import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import {
  DOWNLOAD_REDUCER_TABLE_LIST,
  DOWNLOAD_TABLE_LIST,
  GALLERY_TABLE_LIST,
  NEWS_TABLE_LIST
} from '../constants/Auth';
import { getDownloadsListTableData, getNewsListTableData, getGalleryListTableData } from './HealperSaga';
import { downloadsReducerTableData, galleryReducerTableData, newsReducerTableData } from 'redux/actions/NewsAction';


export function* newsDetailsList() {
  yield takeEvery(NEWS_TABLE_LIST, function* ({ payload }) {
    try {
      const responseData = yield call(getNewsListTableData, payload);
      console.log("respose", responseData);
      yield put(newsReducerTableData(responseData));
    } catch (err) {
      console.log("error", err);
    }
  });
}

export function* downloadsDetailsList() {
  yield takeEvery(DOWNLOAD_TABLE_LIST, function* ({ payload }) {
    try {
      const responseData = yield call(getDownloadsListTableData, payload);
      yield put(downloadsReducerTableData(responseData));
    } catch (err) {
      console.log("error", err);
    }
  });
}

export function* galleryDetailsList() {
  yield takeEvery(GALLERY_TABLE_LIST, function* ({ payload }) {
    try {
      const responseData = yield call(getGalleryListTableData, payload);
      yield put(galleryReducerTableData(responseData));
    } catch (err) {
      console.log("error", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(newsDetailsList),
    fork(downloadsDetailsList),
    fork(galleryDetailsList)
  ]);
}


