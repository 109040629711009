import {
  BOARD_TABLE_LIST,
  BOARD_REDUCER_TABLE_LIST
} from '../constants/Auth';

export const boardMemeberData = (payload) => {
  return {
    type: BOARD_TABLE_LIST,
    payload
  }
};

export const memeberBoardData = (payload) => {
  return {
    type: BOARD_REDUCER_TABLE_LIST,
    payload
  }
};
