import { notification } from "antd";
import service from "auth/FetchInterceptor";
import { setAuthentication } from "auth/LocalStorage";
import { env } from "configs/EnvironmentConfig";
import { noLoading } from "redux/actions/Auth";


export const requestLogin = async (loginReq) => {
    const response = await service.post(env.API_ENDPOINT_URL + 'vendor/auth/login', loginReq);
    if (response?.status) {
        setAuthentication(response.data);
        return response.data;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}

export const getMemberListData = async () => {
    const response = await service.get(env.API_ENDPOINT_URL + 'admin/dashboard/overview');
    if (response?.status) {
        return response.data;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}

export const getMemberListTableData = async (payload) => {
    const response = await service.post(env.API_ENDPOINT_URL + 'admin/dashboard/get-members', payload);
    if (response) {
        return response;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}

export const getKshemanidhiMemberListTableData = async (payload) => {
    const response = await service.post(env.API_ENDPOINT_URL + 'admin/dashboard/get-members', payload);
    if (response) {
        return response;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}

export const getKshemanidhiPensionListTableData = async (payload) => {
    const response = await service.post(env.API_ENDPOINT_URL + 'admin/dashboard/get-members', payload);
    if (response) {
        return response;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}

export const getNewsListTableData = async (payload) => {
    const response = await service.post(env.API_ENDPOINT_URL + 'admin/get-all-news', payload);
    if (response) {
        return response;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}

export const getBoardMemberListData = async (payload) => {
    console.log("payload", payload);
    const response = await service.post(env.API_ENDPOINT_URL + 'admin/get-all-board', payload);
    if (response) {
        return response;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}

export const getDownloadsListTableData = async (payload) => {
    const response = await service.post(env.API_ENDPOINT_URL + 'mobile/find-all-downloads', payload);
    if (response?.status) {
        return response.data;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}

export const getGalleryListTableData = async (payload) => {
    const response = await service.post(env.API_ENDPOINT_URL + 'admin/get-all-gallery', payload);
    if (response) {
        return response;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}

export const getMandalamDropDown = async () => {
    const response = await service.get(env.API_ENDPOINT_URL + 'admin/get-mandalam');
    if (response?.status) {
        return response?.data;
    } else {
        noLoading()
        notification.error({
            message: response?.message,
        })
    }
}