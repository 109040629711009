const setAuthentication = ({ account_status, accessToken, expires_in }) => {
    localStorage.setItem('Authenticated', true);
    localStorage.setItem('account_status', JSON.stringify(account_status));
    localStorage.setItem('accessToken', JSON.stringify(accessToken));
    localStorage.setItem('expires_in', JSON.stringify(expires_in));
};

const removeTokens = () => {
    localStorage.removeItem('Authenticated');
    localStorage.removeItem('account_status');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expires_in');
}

const getAuthenticated = () => localStorage.getItem('Authenticated');
const getAccount_status = () => localStorage.getItem('account_status');
const getAccessToken = () => localStorage.getItem('accessToken');
const getexpires_in = () => localStorage.getItem('expires_in');

module.exports = {
    setAuthentication,
    removeTokens,
    getAuthenticated,
    getAccount_status,
    getAccessToken,
    getexpires_in
};
