import {
	DASH_MEM_LIST,
	DASH_MEM_TABLE_LIST,
	GET_MANDALAM_REDUCER_DATA
} from '../constants/Auth';

const initState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	memberList: {},
	memberTableList: {},
	getMandalamData: []
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case DASH_MEM_LIST:
			return {
				...state,
				loading: false,
				redirect: '/',
				memberList: action.payload
			}
		case DASH_MEM_TABLE_LIST:
			return {
				...state,
				loading: false,
				redirect: '/',
				memberTableList: action.payload
			}
		case GET_MANDALAM_REDUCER_DATA:
			return {
				...state,
				loading: false,
				redirect: '/',
				getMandalamData: action.payload
			}
		default:
			return state;
	}
}

export default auth