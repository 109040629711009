import {
    KSHE_MEMBER_LIST_TABLE,
    KSHE_MEM_TABLE_LIST,
    KSHE_PENSION_LIST_TABLE,
    KSHE_PENSION_TABLE_LIST
  } from '../constants/Auth';

  export const memeberKshemanidhiListTable = (payload) => {
    return {
      type: KSHE_MEMBER_LIST_TABLE,
      payload
    }
  };

  export const memeberKshemanidhiTableData = (payload) => {
    return {
      type: KSHE_MEM_TABLE_LIST,
      payload
    }
  };

  export const pensionKshemanidhiListTable = (payload) => {
    return {
      type: KSHE_PENSION_LIST_TABLE,
      payload
    }
  };

  export const pensionKshemanidhiTableData = (payload) => {
    return {
      type: KSHE_PENSION_TABLE_LIST,
      payload
    }
  };