import {
    KSHE_MEM_TABLE_LIST,
    KSHE_PENSION_TABLE_LIST
} from '../constants/Auth';

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    memberList: {},
    kshemanidhimemberTableList: {},
    kshemanidhiPensionTableList: {},
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case KSHE_MEM_TABLE_LIST:
            return {
                ...state,
                loading: false,
                redirect: '/',
                kshemanidhimemberTableList: action.payload
            }
        case KSHE_PENSION_TABLE_LIST:
            return {
                ...state,
                loading: false,
                redirect: '/',
                kshemanidhiPensionTableList: action.payload
            }
        default:
            return state;
    }
}

export default auth