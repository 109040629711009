import {
	BOARD_REDUCER_TABLE_LIST
} from '../constants/Auth';

const initState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	memberList: {},
	boardMemberTableList: {},
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case BOARD_REDUCER_TABLE_LIST:
			return {
				...state,
				loading: false,
				redirect: '/',
				boardMemberTableList: action.payload
			}
		default:
			return state;
	}
}

export default auth