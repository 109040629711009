import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import {
	GET_MANDALAM_DATA,
	MEMBER_LIST, MEMBER_LIST_TABLE
} from '../constants/Auth';
import { getMandalamDropDown, getMemberListData, getMemberListTableData } from './HealperSaga';
import { getMandalamReducerData, memeberDashboardData, memeberDashboardTableData } from 'redux/actions/dashboardAction';

export function* dashMemerList() {
	yield takeEvery(MEMBER_LIST, function* () {
		try {
			const responseData = yield call(getMemberListData);
			yield put(memeberDashboardData(responseData));
		} catch (err) {
			console.log("error", err);
		}
	});
}

export function* dashMemerListTable() {
	yield takeEvery(MEMBER_LIST_TABLE, function* ({ payload }) {
		try {
			const responseData = yield call(getMemberListTableData, payload);
			yield put(memeberDashboardTableData(responseData));
		} catch (err) {
			console.log("error", err);
		}
	});
}

export function* getMandalamData() {
	yield takeEvery(GET_MANDALAM_DATA, function* () {
		try {
			const responseData = yield call(getMandalamDropDown);
			yield put(getMandalamReducerData(responseData));
		} catch (err) {
			console.log("error", err);
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(dashMemerList),
		fork(dashMemerListTable),
		fork(getMandalamData),
	]);
}


