import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import {
	KSHE_MEMBER_LIST_TABLE, KSHE_PENSION_LIST_TABLE
} from '../constants/Auth';
import { getKshemanidhiMemberListTableData, getKshemanidhiPensionListTableData } from './HealperSaga';
import { memeberKshemanidhiTableData, pensionKshemanidhiTableData } from 'redux/actions/KshemaidhiAction';


export function* kshemandhiMemerList() {
	yield takeEvery(KSHE_MEMBER_LIST_TABLE, function* ({ payload }) {
		try {
			const responseData = yield call(getKshemanidhiMemberListTableData, payload);
			yield put(memeberKshemanidhiTableData(responseData));
		} catch (err) {
			console.log("error", err);
		}
	});
}

export function* kshemandhiPensionList() {
	yield takeEvery(KSHE_PENSION_LIST_TABLE, function* ({ payload }) {
		try {
			const responseData = yield call(getKshemanidhiPensionListTableData, payload);
			yield put(pensionKshemanidhiTableData(responseData));
		} catch (err) {
			console.log("error", err);
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(kshemandhiMemerList),
		fork(kshemandhiPensionList),
	]);
}


