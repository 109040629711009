import {
  NEWS_REDUCER_TABLE_LIST,
  DOWNLOAD_REDUCER_TABLE_LIST,
  GALLERY_REDUCER_TABLE_LIST
} from '../constants/Auth';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  newsTableList: {},
  downloadsTableList: {},
  galleryTableList: [],
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case NEWS_REDUCER_TABLE_LIST:
      return {
        ...state,
        loading: false,
        redirect: '/',
        newsTableList: action.payload
      }
    case DOWNLOAD_REDUCER_TABLE_LIST:
      return {
        ...state,
        loading: false,
        redirect: '/',
        downloadsTableList: action.payload
      }
    case GALLERY_REDUCER_TABLE_LIST:
      return {
        ...state,
        loading: false,
        redirect: '/',
        galleryTableList: action.payload
      }
    default:
      return state;
  }
}

export default auth