import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import {
  BOARD_TABLE_LIST
} from '../constants/Auth';
import { getBoardMemberListData } from './HealperSaga';
import { memeberBoardData } from 'redux/actions/boardMembersAction';

export function* boardMemerList() {
  yield takeEvery(BOARD_TABLE_LIST, function* ({ payload }) {
    try {
      const responseData = yield call(getBoardMemberListData, payload);
      console.log("response", responseData);
      yield put(memeberBoardData(responseData));
    } catch (err) {
      console.log("error", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(boardMemerList),
  ]);
}