import {
  DASH_MEM_LIST,
  DASH_MEM_TABLE_LIST,
  MEMBER_LIST,
  MEMBER_LIST_TABLE,
  GET_MANDALAM_DATA,
  GET_MANDALAM_REDUCER_DATA
} from '../constants/Auth';

export const memeberCardData = () => {
  return {
    type: MEMBER_LIST
  }
};

export const memeberDashboardData = (payload) => {
  return {
    type: DASH_MEM_LIST,
    payload
  }
};

export const memeberListTable = (payload) => {
  return {
    type: MEMBER_LIST_TABLE,
    payload
  }
};

export const getDropDownDataAction = (payload) => {
  return {
    type: GET_MANDALAM_DATA,
    payload
  }
};

export const memeberDashboardTableData = (payload) => {
  return {
    type: DASH_MEM_TABLE_LIST,
    payload
  }
};

export const getMandalamReducerData = (payload) => {
  return {
    type: GET_MANDALAM_REDUCER_DATA,
    payload
  }
}; 
