import {
  NEWS_TABLE_LIST,
  NEWS_REDUCER_TABLE_LIST,
  DOWNLOAD_TABLE_LIST,
  DOWNLOAD_REDUCER_TABLE_LIST,
  GALLERY_TABLE_LIST,
  GALLERY_REDUCER_TABLE_LIST
} from '../constants/Auth';

export const newsDetailsListTable = (payload) => {
  return {
    type: NEWS_TABLE_LIST,
    payload
  }
};

export const newsReducerTableData = (payload) => {
  return {
    type: NEWS_REDUCER_TABLE_LIST,
    payload
  }
};

export const downloadsListTable = (payload) => {
  return {
    type: DOWNLOAD_TABLE_LIST,
    payload
  }
};

export const downloadsReducerTableData = (payload) => {
  return {
    type: DOWNLOAD_REDUCER_TABLE_LIST,
    payload
  }
};

export const galleryListTable = (payload) => {
  return {
    type: GALLERY_TABLE_LIST,
    payload
  }
};

export const galleryReducerTableData = (payload) => {
  return {
    type: GALLERY_REDUCER_TABLE_LIST,
    payload
  }
};

